import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "48",
  height: "48",
  viewBox: "0 0 48 48",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M19 31L33 22L19 13V31ZM16 42V38H8C6.9 38 5.95833 37.6083 5.175 36.825C4.39167 36.0417 4 35.1 4 34V10C4 8.9 4.39167 7.95833 5.175 7.175C5.95833 6.39167 6.9 6 8 6H40C41.1 6 42.0417 6.39167 42.825 7.175C43.6083 7.95833 44 8.9 44 10V34C44 35.1 43.6083 36.0417 42.825 36.825C42.0417 37.6083 41.1 38 40 38H32V42H16ZM8 34H40V10H8V34Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#F06F02",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M33 22L19 31V13L33 22Z"
    }, null, -1)
  ])))
}
export default { render: render }